import Bootstrap from 'bootstrap';
import '../../node_modules/@fortawesome/fontawesome-free/js/all.min.js';

// datetimepicker
global.moment = require('moment');
require('tempusdominus-bootstrap-4');
import '../css/main.scss';

import  './dependent_fields_visibility.js'
import  './calcGfa.js'

// bootstrap custom client-side form validation
// https://getbootstrap.com/docs/4.4/components/forms/
jQuery(function($){
	$(window).on('load', function(){
		$('.needs-validation').on('submit', function(event){
			var $form = $(this);
			//$('.added_feedback_message').remove();
			if(false === $form[0].checkValidity()){
				console.log('form invalid');
				event.preventDefault();
				event.stopPropagation();
				
			//	$form.find('.form-group').each(function(){
			//		var $lastEl;
			//		$(this).find('.form-check-input:invalid, .form-control:invalid').each(function(){
			//			//$(this).closest('div').before('<span class="added_feedback_message invalid-feedback d-block">Jāaizpilda obligāti!</span>');
			//			//return false;
			//			
			//			$lastEl = $(this);
			//		});
			//		if($lastEl){
			//			
			//			$lastEl.parent().append('<div class="added_feedback_message invalid-feedback">Jāaizpilda obligāti!</div>');
			//		}
			//	});
			}
			$form.addClass('was-validated');
		});
	});
	
	// datetimepicker widget
	$('input.datepicker').each(function(){
		var $this = $(this);
		$this.datetimepicker({
			format: 'DD.MM.YYYY',
			locale: 'lv'
		});
		
		$this.on('focus, click', function(){
			$(this).datetimepicker('toggle');
		}).on('blur', function(){
			$(this).datetimepicker('hide');
		});
	});
});
