(function ($, window) {
	$.fn.hideUnless = function($element, showValues){
		var formGroups = [];
		this.each(function(){
			formGroups = formGroups.concat($(this).closest('.form-group').get());
		});
		formGroups = $.uniqueSort(formGroups);
		//console.info(formGroups);
		
		$.each(formGroups, function(ix, el){
			var $dependFormGroup = $(el);
			$element.on('change', function(event, animationDuration = 200){
				if($element.is(':visible') && $element.hasSelectedValue() && $element.isValueSelected(showValues)){
					$dependFormGroup.showDependElement(animationDuration, function(){
						$dependFormGroup.find('input, select').manageRequired();
						$dependFormGroup.find('input, select').dependElementTrigger();
					});
				}else{
					$dependFormGroup.hideDependElement(animationDuration, function(){
						$dependFormGroup.find('input, select').manageRequired();
						$dependFormGroup.find('input, select').dependElementTrigger();
					});
				}
			});
		});
		
		$element.dependElementTrigger();
		return;
		
		
		
		
		
		//var lastElName = '';
		this.each(function(){
			var $dependEl = $(this);
		//	var elName = $dependEl.attr('name');
		//	if(elName && elName != lastElName){
		//		lastElName = elName;
				$element.on('change', function(event, animationDuration = 200){
					$formElements = [];
					if($element.is(':visible') && $element.hasSelectedValue() && $element.isValueSelected(showValues)){
						$dependEl.closest('.form-group').showDependElement(animationDuration, function(){
							$dependEl.manageRequired();
							$dependEl.dependElementTrigger();
						});
					}else{
						$dependEl.closest('.form-group').hideDependElement(animationDuration, function(){
							$dependEl.manageRequired();
							$dependEl.dependElementTrigger();
						});
					}
				});
		//	}
		});
		
		$element.dependElementTrigger();
	};
	
	$.fn.dependElementTrigger = function(){
		$element = $(this);
		if($element.is('select')){
			$element.trigger('change', [100]);
			return;
		}
		if($element.is('input[type="checkbox"], input[type="radio"]')){
			var $triggerEl = $element.filter(':checked');
			if(!$triggerEl.length) $triggerEl = $element.first();
			$triggerEl.trigger('change', [100]);
		}
	};
	
	$.fn.showDependElement = function(animationDuration = 200, callback){
		$(this).filter(':hidden').slideDown(animationDuration, callback);
	};
	
	$.fn.hideDependElement = function(animationDuration = 200, callback){
		$(this).filter(':visible').slideUp(animationDuration, callback);
	};
	
	$.fn.manageRequired = function(){
		this.each(function(){
			$field = $(this);
			if($field.is(':visible')){
				if($field.data('init_required')) $field.prop('required', true);
				return;
			}
			
			if('undefined' === typeof $field.data('init_required')){
				$field.data('init_required', $field.prop('required'));
			}
			$field.prop('required', false);
		});
	};
	
	$.fn.hasSelectedValue = function(){
		$el = $(this);
		if($el.is('select')){
			$checkedOpt = $el.find('option:checked');
			if(!$checkedOpt.length) return false;
			if('' === $checkedOpt.val()) return false;
			return true;
		}
		
		if($el.is('input[type="checkbox"], input[type="radio"]')){
			return !!$el.filter(':checked').length;
		}
	};
	
	$.fn.isValueSelected = function(values){
		$el = $(this);
		if($el.is('select')){
			var selVal = $el.find('option:checked').val();
			//console.info(selVal);
			return ($.inArray(selVal, values) > -1);
		}
		
		if($el.is('input[type="checkbox"], input[type="radio"]')){
			var isChecked = false;
			$el.filter(':checked').each(function(){
				var selVal = $(this).val();
				if($.inArray(selVal, values) > -1){
					isChecked = true;
					return;
				}
			});
			return isChecked;
		}
		
		return false;
	};

})(jQuery, window);