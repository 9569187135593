window.calcGfaKg = function(cr, age, gender, weight){
	if(cr < 0) return 0;
	if(age < 0) return 0;
	if(weight < 0) return 0;
	if(!gender) return 0;
	var c = (gender == 'M' ? 1.23 : 1.04);
	var res = ((140 - age) * weight * c) / cr;
	
	return res;
};

window.calcGfaMdrd = function(cr, age, gender, color = ''){
	if(cr < 0) return 0;
	if(age < 0) return 0;
	if(!gender) return 0;
	
	var res = 32788 * Math.pow(cr, -1.154) * Math.pow(age, -0.203);
	if(color == 'B') res = res * 1.212;
	if(gender == 'F') res = res * 0.742;
	
	return res;
};

window.calcGfaCkdEpi = function(cr, age, gender, color = ''){
	if(cr < 0) return 0;
	if(age < 0) return 0;
	if(!gender) return 0;
	
	var k = ('F' == gender ? 61.9 : 79.6);
	var a = ('F' == gender ? -0.329 : -0.411);
	var res = 141
		* Math.pow(Math.min(1, cr/k), a)
		* Math.pow(Math.max(1, cr/k), -1.209)
		* Math.pow(0.993, age);
	if(gender == 'F') res = res * 1.018;
	if(color == 'B') res = res * 1.159;
	
	return res;
};
